.IntroContainer{
    background: url(../Design/space.jpeg);
    background-attachment: fixed;
}

.IntroHeading{
    font-family: 'ui-sans-serif';
}
.IntroPara{
    font-size: 1.2rem !important;
}

@media screen and (min-width: 360px) {
    .IntroContainer{
        background: url(../Design/space.jpeg);
        background-attachment: fixed;
    }
    .movingText{
        font-size: larger !important;
        margin: 0;
        padding: 0 !important;
    }
  }