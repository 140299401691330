body {
    background: url(../Design/space.jpeg);
    background-attachment: fixed;
}

#about {
    opacity: 0.95;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    padding-top: 40px;
    padding-bottom: 40px;
}

.aboutHead {
    color: rgb(255, 255, 255);
    border-radius: 10px;
    text-align: center;
    width: 80%;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: ui-sans-serif;
}

.aboutPara {
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 1.25rem;
    border-radius: 15px;
    text-align: center;
    width: 80%;
    margin-top: 20px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 360px) {
    #about h4{
        padding: 0;
        width: 100%;
        justify-content: center;
    }

    .aboutPara{
        width: 80vw !important;
        margin-left: 5vw;
    }
  }