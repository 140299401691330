.parent_wrapper {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: space-around;
}
.pos {
  text-align: center;
  font-size: 1rem;
}
.position {
  height: 29px;
  font-family: ui-sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  position: absolute;
  width: 302px;
  top: 340px;
  text-align: center;
}

.team-card {
  width: 300px;
  height: 405px;
  margin-right: 5%;
  margin-bottom: 5%;
  background: #979090 ;
  border: 1.5px solid #000000;
  border-radius: 30px;
}

.team-card:hover {
  box-shadow: 4px 8px 0px gray;
  transition: all 0.5s ease;
}

.cardImg {
  object-fit: cover;
  width: 245px;
  height: 245px;
  margin: 25px 0px 0px 25px;
  overflow: hidden;
  background: #ffffff;
  border: 4px solid #000000;
  border-radius: 1000px;
  transition: all 0.5s ease;
}

.content {
  padding: 25px;
}

.cardImg:hover {
  box-shadow: 0px 0px 0px;
  transition: all 0.4s ease;
}

.cardImg img {
  object-fit: cover;
  width: 241px;
  height: 280px;
  background: #ffffff;
  border-radius: 19px;
  transition: all 0.5s ease;
}

.cardImg img:hover {
  opacity: 0.95;
}
.container {
  padding: 2px 16px;
}

.pos {
  padding: 2px;
  color: #000000;
}

@media screen and (max-width: 700px) {
  .team-card {
    width: 280px;
    height: 380px;
    margin-left: -30px;
    display: flex;
    justify-content: space-between;
  }

  .parent_wrapper {
    width: 100%;
  }

  .cardImg {
    width: 225px;
    height: 225px;
  }

  .cardImg img {
    width: 235px;
    height: 270px;
  }

  .position {
    position: absolute;
    top: 330px;
    width: 280px;
  }

  .cardTitle {
    position: absolute;
    top: 360px;
    width: 280px;
  }

  .drink {
    position: absolute;
    top: 235px;
  }
}
