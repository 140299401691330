.container {
  display: flex;
  flex-direction: column; 
}

.pos_section {
  margin-top: 30px;
  margin-left: 10px;
}
.pos_title h1 {
  position: relative;
  font-family: ui-sans-serif;
  text-align: center;
  font-weight: 300;
  font-size: 1.8em;
  color: rgb(255, 255, 255);
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 0;
  padding-right: 20px;
  line-height: 1.5em;
  margin-top: 25px;
  padding-bottom: 15px;
  position: relative;
}
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content:space-around;
  padding-left: 32px;
}
.mssg {
  margin-top: 20px;
  text-align: left;
  margin-right: 50px;
}
.mssg h2 {
  font-family: ui-sans-serif;
  font-size: 24px;
  font-style: normal;
  margin-top: 20px;
  font-weight: 500;
  line-height: 33px;;
  margin: 27px 36px;
  text-align: justify;
}

@media screen and (max-width: 1050px){
  .sponsor_section{
    margin-left: -12px;
  }
}

@media screen and (max-width: 900px){
  .sponsor_section{
    margin-left: -10%;
  }
}

@media screen and (max-width: 700px) {
  .sponsor_section{
    margin-left: 30px;
  }

  .mssg h2{
    margin: 27px 0px;
  }

  .pos_title h1{
    text-align: center;
    padding-left: 0px;
  }

  .cards{
    display: flex;
    justify-content: space-evenly;
    padding-left: 10%;
  }
}

