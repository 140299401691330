.item {
  margin-top: 0px;
  padding-top: 10px;
  padding-bottom: 5px;
  text-align: centre;
  color: white;
  font-family: "ui-sans-serif";
}
.box:hover{
  background-color: #979090;
  border-radius: 15px;
  color: black;
}
.item:hover{
  color: black;
}
