.GalleryHead {
  color: rgb(255, 255, 255);
  font-family: "ui-sans-serif";
  border-radius: 10px;
  text-align: center;
  width: 46%;
  margin-left: 25%;
  margin-right: 25%;
}

.swiper-pagination-bullet {
  background-color: #ffffff;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.swiper-slide-shadow {
  opacity: 0;
}
