/* @tailwind base;
@tailwind components;
@tailwind utilities; */

footer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.body{
    background: url(../Design/black-gradient.jpg);
}

li{
    font-family: "sans";
}

.social-links::after{
    background-color: #ffffff;
    content:"";
    height:0.15rem;
}

.social-links:hover::after{
    display:block;
    animation: left-to-right 0.25s cubic-bezier(.694,.048,.335,1) ;
    background-color: #ffffff;
}


@keyframes left-to-right{
    0%{

        width:0;
    }
    100%{
        width:100%;
    }
}
@media screen and (max-width: 360px) {
    
}
